import { Link, VSpacing, Divider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { usePlatform } from 'src/hooks/usePlatform';
import { Platform } from 'src/models/locale';
import { SearchType } from 'src/types/search/common';

const ARTICLE_LINKS = {
    [SearchType.Vacancy]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: '/article/1175' },
    [SearchType.Resume]: {
        [Platform.HeadHunter]: '/article/23786',
        [Platform.Zarplata]: '/article/25295?hhtmFrom=resume_search_form',
    },
};

const TrlKeys = {
    link: 'novafilters.searchLanguageLink',
};

const SearchLanguageLink: TranslatedComponent = ({ trls }) => {
    const { searchType, isEmployerViewPage, isResumeSearchType } = useSearchSettings();
    const platform = usePlatform();

    if (isEmployerViewPage) {
        return null;
    }

    return (
        <>
            {!isResumeSearchType && <Divider marginBottom={24} />}
            <Link href={ARTICLE_LINKS[searchType][platform]} enableVisited>
                {trls[TrlKeys.link]}
            </Link>
            <VSpacing default={24} />
        </>
    );
};

export default translation(SearchLanguageLink);
